import { patterns, TYPE_ATTRIBUTE, CONSENT_VALUE, settings } from './variables'

export const isOnBlacklist = function (src, type, dataConsent) {
    if (settings.checkAllScripts) {
        return (src &&
            (!type || type !== TYPE_ATTRIBUTE) &&
            (
                (!patterns.blacklist || patterns.blacklist.some(pattern => pattern.test(src))) &&
                (!patterns.whitelist || patterns.whitelist.every(pattern => !pattern.test(src)))
            )) || (
                dataConsent === CONSENT_VALUE
            )
    } else{
        return dataConsent === CONSENT_VALUE
    }
}

export const willBeUnblocked = function (script) {
    const src = script.getAttribute('src')
    return (
        patterns.blacklist && patterns.blacklist.every(entry => !entry.test(src)) ||
        patterns.whitelist && patterns.whitelist.some(entry => entry.test(src))
    )
}