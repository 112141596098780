export const TYPE_ATTRIBUTE = 'javascript/blocked'
export const CONSENT_VALUE = 'block'

export const patterns = {
    blacklist: window.YETT_BLACKLIST,
    whitelist: window.YETT_WHITELIST
}

// ipsis
export const settings = {
    checkAllScripts:  window.YETT_CHECKALLSCRIPTS
}

// Backup list containing the original blacklisted script elements
export const backupScripts = {
    blacklisted: []
}